import ajax from "superagent";
import { Album, EventType } from "../components";
import { addParamToURL } from "../../../utils/BrowserHelper";

export const fetchEventList = async (
  page: number,
  setEvents: (val: EventType[], nextPage: number | null) => void,
  filter: string | null = "all"
) => {
  try {
    const response = await fetch(
      `/api/v1/photo-manager/contents?t=root&page=${page}&event_timing_scope=${filter}`,
      {
        headers: {
          "Cache-Control": "no-store",
          Pragma: "no-cache"
        },
        cache: "no-store"
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    const meta = data.meta;
    setEvents(data.entities, meta.nextPage);
  } catch (error) {
    console.error("Failed to fetch events:", error);
  }
};

export const fetchAlbumDetails = async (eventId: number) => {
  try {
    const response = await fetch(
      `/api/v1/photo-manager/contents?t=Gallery&id=${eventId}`,
      {
        headers: {
          "Cache-Control": "no-store",
          Pragma: "no-cache"
        },
        cache: "no-store"
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.entities;
  } catch (error) {
    console.error(`Failed to fetch albums for event ${eventId}:`, error);
    return [];
  }
};

export const fetchAlbumPhotos = async (albumId: number, page: number) => {
  try {
    const response = await fetch(
      `/api/v1/photo-manager/contents?t=Album&id=${albumId}&page=${page}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to fetch photos for album ${albumId}:`, error);
    return [];
  }
};

export const fetchEventsByName = async (name: string) => {
  try {
    const response = await fetch(`/api/v1/photo-manager/search?q=${name}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to fetch events with the name ${name}:`, error);
    return [];
  }
};

export const handleCreateAlbum = async (
  albumName: string,
  groupPhotos: boolean,
  selectedEvent: EventType,
  groupPhotosEnabled: boolean,
  onCreateAlbumSuccess: (newAlbum: Album) => void
) => {
  try {
    const response = await ajax.post(
      `/api/v1/albums?event_id=${selectedEvent.id}&name=${albumName}${
        groupPhotosEnabled ? `&group_photos=${groupPhotos}` : ""
      }`
    );

    if (!response.ok) {
      throw new Error("Failed to create album");
    }

    const newAlbum = response.body.album;
    onCreateAlbumSuccess(newAlbum);
  } catch (error) {
    console.error("Error creating album:", error);
    // @ts-ignore
    toastr.error("Unable to create album. Please try again", null, 1000);
  }
};

export const submitAlbumName = (
  e: any,
  albumId: number,
  albumName: string,
  hasInvalidatedValues: Function,
  setAlbumNameDrafts: Function,
  setAlbumsInEditMode: Function,
  onEditSuccess: (albums: Album[] | Album) => void,
  albumsData: Album[]
) => {
  e.stopPropagation();
  if (hasInvalidatedValues(albumId, true)) {
    return;
  }

  fetch(`/api/v1/album/${albumId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ name: albumName })
  })
    .then(response => {
      if (!response.ok) {
        const errorMsg =
          response.status === 404
            ? "Album not found."
            : "Failed to update album name.";
        console.error(errorMsg);
        throw new Error(errorMsg);
      }
      return response.json();
    })

    .then(updatedAlbum => {
      setAlbumNameDrafts((prev: any) => {
        const updatedNames = { ...prev };
        delete updatedNames[albumId];
        return updatedNames;
      });

      setAlbumsInEditMode((prev: any[]) =>
        prev.filter((id: number) => id !== albumId)
      );

      if (albumsData.length > 0) {
        const updatedAlbums = albumsData.map(album =>
          album.id === albumId
            ? { ...album, name: updatedAlbum.album.name }
            : album
        );
        onEditSuccess(updatedAlbums);
      } else {
        onEditSuccess(updatedAlbum.album);
      }
    })
    .catch(error => {
      console.error("Error while updating album name:", error);
    });
};

export const handleDelete = async (
  setLoading: Function,
  albumId: number,
  onDeleteSuccess: Function,
  onClose: Function
) => {
  setLoading(true);
  try {
    const response = await ajax.delete(`/api/v1/albums/${albumId}`);
    if (!response.ok) {
      throw new Error("Failed to delete album");
    }
    onDeleteSuccess();
    onClose();
  } catch (error) {
    console.error("Error deleting album:", error);
    // @ts-ignore
    toastr.error("Unable to delete album. Please try again", null, 1000);
  } finally {
    setLoading(false);
  }
};

export const deletePhotos = async (
  selectedMediaId: Array<number>,
  selectedEventId: number,
  onDeletePhotoSuccess: Function,
  selectedAlbumId: number
) => {
  try {
    await $.ajax({
      url: `/api/v1/photos/delete`,
      type: "DELETE",
      data: {
        photo_ids: Array.from(selectedMediaId),
        event_id: selectedEventId
      }
    });

    // @ts-ignore
    toastr.success("Successfully deleted");
    onDeletePhotoSuccess();

    // Refresh the photos after deletion
  } catch (err) {
    // @ts-ignore
    toastr.error("Failed to delete");
    console.error(err);
  }
};

export const handleSave = (
  photoId: number,
  userId: number,
  onSuccess: Function
) => {
  ajax
    .post(addParamToURL(`/api/v1/photos/${photoId}/save`, "user_id", userId))
    .then(res => {
      onSuccess(true);
      //@ts-ignore
      toastr.success("Photo saved", null, { timeOut: 1000 });
    });

  return true;
};

export const handleUnsave = (
  photoId: number,
  userId: number,
  onSuccess: Function
) => {
  ajax
    .delete(
      addParamToURL(`/api/v1/photos/${photoId}/unsave`, "user_id", userId)
    )
    .then(res => {
      onSuccess(false);
      //@ts-ignore
      toastr.success("Photo unsaved", null, { timeOut: 1000 });

      return true;
    });
};

export const hidePhotos = async (
  selectedMediaId: any,
  hideOrUnhideText: string,
  selectedEventId: number,
  clearSelection: Function,
  fetchAlbumPhotosFunction: Function,
  selectedAlbum: any,
  photoCurrentPage: number
) => {
  try {
    await $.ajax({
      url: `/api/v1/photos/${hideOrUnhideText}`,
      type: "POST",
      data: {
        photo_ids: Array.from(selectedMediaId),
        event_id: selectedEventId
      }
    });

    // @ts-ignore
    toastr.success("Successfully updated");
    clearSelection();
    // Refresh the photos to get updated hidden status
    if (selectedAlbum) {
      fetchAlbumPhotosFunction(selectedAlbum, photoCurrentPage);
    } else {
    }
  } catch (err) {
    // @ts-ignore
    toastr.error("Failed to update");
    console.error(err);
  }
};

export const hidePhoto = async (
  selectedMediaId: any,
  hideOrUnhideText: string,
  selectedEventId: number,
  onSuccess: Function,
  selectedAlbum: any,
  fetchAlbumPhotosFunction: Function,
  photoCurrentPage: number
) => {
  try {
    await $.ajax({
      url: `/api/v1/photos/${hideOrUnhideText}`,
      type: "POST",
      data: {
        photo_ids: [selectedMediaId],
        event_id: selectedEventId
      }
    });

    // @ts-ignore
    toastr.success("Successfully updated");
    // Refresh the photos to get updated hidden status
    onSuccess(hideOrUnhideText === "hide" ? true : false);
    if (selectedAlbum) {
      fetchAlbumPhotosFunction(selectedAlbum, photoCurrentPage);
    }
  } catch (err) {
    // @ts-ignore
    toastr.error("Failed to update");
    console.error(err);
  }
};

export const handleDownload = async (
  selectedMediaId: Set<number>,
  selectedEventId: number
) => {
  if (selectedMediaId.size === 0) return;

  const photoIds = Array.from(selectedMediaId);
  const params = new URLSearchParams();
  params.append("t", "Gallery");
  params.append("id", selectedEventId.toString());
  photoIds.forEach(id => params.append("photo_ids[]", id.toString()));
  const url = `/api/v1/photo-manager/download?${params.toString()}`;

  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Failed to fetch download URL");

    const data = await response.json();
    if (data.download_url) {
      window.location.href = data.download_url; // Trigger ZIP download
    }
  } catch (error) {
    console.error("Download failed:", error);
    // @ts-ignore
    toastr.error("Failed to download photos. Please try again", null, 1000);
  }
};

export const fetchEventData = async (
  eventId: number,
  setSelectedEvent: (event: any) => void,
  setLoadingEvent: (loading: boolean) => void
) => {
  try {
    setLoadingEvent(true);
    const response = await fetch(
      `/api/v1/photo-manager/details/?t=Gallery&id=${eventId}`
    );

    if (!response.ok) throw new Error("Failed to fetch event data");

    const data = await response.json();
    setSelectedEvent(data.entities);
  } catch (error) {
    console.error("Error fetching event details:", error);
  } finally {
    setLoadingEvent(false);
  }
};

export const fetchAlbumData = async (
  albumId: number,
  setSelectedAlbum: (album: any) => void,
  setLoadingAlbum: (loading: boolean) => void
) => {
  try {
    setLoadingAlbum(true);
    const response = await fetch(
      `/api/v1/photo-manager/details/?t=Album&id=${albumId}`
    );

    if (!response.ok) throw new Error("Failed to fetch album data");

    const data = await response.json();
    setSelectedAlbum(data.entities);
  } catch (error) {
    console.error("Error fetching album details:", error);
  } finally {
    setLoadingAlbum(false);
  }
};
