import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { fetchEventsByName } from "../PhotoManager/API/api";

const EventSearchBar = ({
  placeholder,
  isMobile = false
}: {
  placeholder: string;
  isMobile: boolean;
}) => {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    $("#close-search-only-nav").on("click", function () {
      $("#mobile-nav-with-only-search").hide().removeClass("only-search-show");;
      $("#mobile-nav-with-icons").show();
    });

    // Cleanup the event listener when the component unmounts
    return () => {
      $("#close-search-only-nav").off("click");
    };
  }, []);

  const fetchDebouncedResults = useCallback(
    debounce(async searchTerm => {
      if (!searchTerm.trim()) {
        setSearchResults([]);
        setNoResults(false);
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const events = await fetchEventsByName(searchTerm);
      setSearchResults(events.entities);
      setNoResults(events.entities.length === 0);
      setIsLoading(false);
    }, 700),
    []
  );
  useEffect(() => {
    fetchDebouncedResults(query);
  }, [query, fetchDebouncedResults]);

  return (
    <div className="top-bar-search">
      <div
        className={`v3-search-container ${
          searchResults.length > 0 || noResults
            ? isMobile
              ? "search-border-bottom"
              : "search-border-full"
            : null
        }`}
      >
        {isMobile ? (
          <div className="search-highlighter">
            <input
              type="text"
              placeholder={placeholder}
              className="search-bar"
              value={query}
              onChange={e => setQuery(e.target.value)}
              id="event-search-mobile"
            />
            <div id="close-search-only-nav" className="search-close">
              <img
                alt="close-search"
                src="/images/website/home/close.svg"
                className="close-search-nav-icon"
              ></img>
            </div>
            {isLoading ? (
              <div className="v3-spinner-mob">
                <span className="fa fa-spinner fa-spin"></span>
              </div>
            ) : null}
          </div>
        ) : (
          <>
            <input
              type="text"
              placeholder={placeholder}
              className="search-bar"
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
            {isLoading ? (
              <div className="v3-search-loading-spinner">
                <span className="fa fa-spinner fa-spin"></span>
              </div>
            ) : null}
          </>
        )}
        {noResults && !isLoading && (
          <ul className="search-results">
            <p className="no-results-text">No search results found</p>
          </ul>
        )}

        {searchResults.length > 0 ? (
          <ul className="search-results">
            {searchResults.map((event: any) => (
              <li key={event.id} className="search-result-item">
                <a href={event.eventLandingURL}>
                  <div className="event-title">
                    <div className="event-ptitle">{event.name}</div>
                    <div className="event-stitle">
                      {event.date} <span className="event-desc-divider">•</span>{" "}
                      {event.location}
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default EventSearchBar;
